import React, { useEffect } from 'react'
import { BackArrowIcon } from './icons'
import styles from './back-button.module.css'

export const BackButton = props => {
  const { to, children, onClick } = props

  return (
    <span className={styles.back}>
      <a href={to} onClick={onClick}>
        <BackArrowIcon />
        <span>{children}</span>
      </a>
    </span>
  )
}
