import React from 'react'

import { IconButton } from '../../../../../components/icon-button'
import { CloseXIcon } from '../icons'
import styles from './thank-you-template.module.css'

export const ThankYouTemplate = (props) => {
  const { children, back, onClose } = props

  return (
    <div className={styles.modal}>
      <div className={styles.template}>
        <div className={styles.close}>
          <IconButton onClick={onClose}>
            <CloseXIcon />
          </IconButton>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.backBlock}>{back}</div>
      </div>
    </div>
  )
}
