import React from 'react'
import { FailedStatusIcon } from '../../components/icons'
import { Button2 } from '../../../../../components/button2'
import { BackButton } from '../../components/back-button'
import { ThankYouTemplate } from '../../components/templates/thank-you-template'

export const ThankYouPaymentNotCompleted = () => {
  const handleClose = () => {
    window.location.href = '/order-food-online/order-food-delivery'
  }

  return (
    <ThankYouTemplate
      onClose={handleClose}
      back={<BackButton to="/#calculator">Zpět k výběru platby</BackButton>}
    >
      <header>
        <FailedStatusIcon />
        <h1>Platba nebyla dokončena.</h1>
      </header>
      <p>Je nám líto, ale něco se porouchalo a vaše platba nebyla dokončena.</p>
      <Button2 color="secondary">Zkusit znovu</Button2>
    </ThankYouTemplate>
  )
}
